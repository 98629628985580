
import { defineComponent, ComputedRef, computed } from "vue";
import DetailsWindow from "@/common-components/DetailsWindow.vue";
import SumAmountInput from "./SumAmountInput.vue";
import { useStore } from "vuex";
import * as Getters from "@/store/constants/Getters";

export default defineComponent({
	name: "PaymentInfo",
	components: {
		SumAmountInput,
		DetailsWindow,
	},
	props: {
		sellerName: {
			type: String,
			require: true,
		},
		partnerOrderId: {
			type: String,
			require: true,
		},
		sumAmount: {
			type: String,
			require: true,
		},
		description: {
			type: String,
			require: true,
		},
		isHiddenDescription: {
			type: Boolean,
			require: true,
		},
		isAmountEditable: {
			type: Boolean,
			require: true,
		},
	},
	setup(props, { emit }) {
		const store = useStore();

		const handleSumAmountInput = (sumAmountInputValue: string) => {
			emit("changeSumAmount", sumAmountInputValue);
		};

		const isOrderNumberHidden: ComputedRef<boolean> = computed(() => {
			return store.getters[Getters.ECOM__GET_IS_ORDER_NUMBER_HIDDEN];
		});

		return {
			handleSumAmountInput,
			isOrderNumberHidden,
		};
	},
});
