
import { defineComponent } from "vue";

export default defineComponent({
	name: "PaymentMethodsSkeleton",
	props: {
		segmentsQuantity: {
			type: Number,
			isRequired: true,
		},
	},
	setup(props: any) {
		const segments = () => new Array(props.segmentsQuantity);

		return {
			segments,
		};
	},
});
