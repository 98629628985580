
import { defineComponent, ref } from "vue";
import TestCardInfoContent from "./TestCardInfoContent.vue";

export default defineComponent({
	name: "TestCardInfoPanel",
	components: {
		TestCardInfoContent,
	},
	setup() {
		const isPopupVisible = ref(false);

		const showTestCardPopup = () => {
			isPopupVisible.value = true;
		};

		return {
			showTestCardPopup,
			isPopupVisible,
		};
	},
});
