import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("article", null, [
      _createVNode(_component_q_btn, {
        flat: "",
        icon: "content_copy",
        dense: "",
        round: "",
        size: "0.8em",
        color: "grey-6",
        class: "q-mr-xs",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCopy(_ctx.cardNumber)))
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.cardNumber), 1)
    ]),
    _createElementVNode("article", null, [
      (_ctx.otpCode)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "OTP code: " + _toDisplayString(_ctx.otpCode), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}