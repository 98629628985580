import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segmentsQuantity, (method) => {
      return (_openBlock(), _createBlock(_component_q_skeleton, {
        type: "rect",
        class: "skeleton",
        key: method
      }))
    }), 128))
  ]))
}