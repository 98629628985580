import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/visa-and-mastercard-logo.svg'


const _hoisted_1 = {
  class: "row justify-between",
  id: "card-payment-button-content-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    ref: "card-pay-btn",
    "text-color": "grey-6",
    color: "grey-2",
    id: "card-payment-button",
    unelevated: "",
    "no-caps": "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCardPayButtonClick()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_icon, {
          name: "credit_card",
          class: "col-1",
          color: "grey-6"
        }),
        _cache[1] || (_cache[1] = _createElementVNode("div", {
          id: "button-label",
          class: "col-auto"
        }, "Банківська картка", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { id: "card-pay-button-icon" }, [
          _createElementVNode("img", {
            class: "col-4",
            src: _imports_0,
            style: {"width":"100%"}
          })
        ], -1))
      ])
    ]),
    _: 1
  }, 512))
}