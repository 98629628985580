export default class Timer {
	private _timer: any;

	public startTimer(delayMs: number, callback: () => any): void {
		this._timer = setTimeout(callback, delayMs);
	}

	public cancelTimer(): void {
		if (this._timer) {
			clearTimeout(this._timer);
		}
	}
}
