import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "absolute-bottom",
  id: "error-message-popup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "arrow-top" }, null, -1)),
    _createVNode(_component_q_icon, {
      name: _ctx.icon,
      class: "q-ma-xs"
    }, null, 8, ["name"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.errorMessage), 1)
  ]))
}