
import NoPaymentMethodsAvailable from "@/components/NoPaymentMethodsAvailable/NoPaymentMethodsAvailable.vue";
import PaymentAttemptsLimit from "@/components/PaymentAttemptsLimit/PaymentAttemptsLimit.vue";
import PaymentCancel from "@/components/PaymentCancel/PaymentCancel.vue";
import PaymentConfirmation from "@/components/PaymentConfirmation/PaymentConfirmation.vue";
import PaymentSuccess from "@/components/PaymentSuccess/PaymentSuccess.vue";
import PaymentTimeout from "@/components/PaymentTimeout/PaymentTimeout.vue";
import ReceiptError from "@/components/ReceiptError/ReceiptError.vue";
import ReceiptSuccess from "@/components/ReceiptSuccess/ReceiptSuccess.vue";
import { EcomCheckoutScreen } from "@/enums/EcomCheckoutScreen";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";
import * as Getters from "@/store/constants//Getters";
import QCard from 'quasar/src/components/card/QCard.js';;
import { computed, ComputedRef, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
	name: "CheckoutDialog",
	components: {
		QCard,
		PaymentCancel,
		PaymentAttemptsLimit,
		PaymentSuccess,
		PaymentTimeout,
		ReceiptSuccess,
		ReceiptError,
		PaymentConfirmation,
		NoPaymentMethodsAvailable,
	},
	props: {
		isLoading: Boolean,
	},
	setup() {
		const store = useStore();

		const currentScreen: ComputedRef<EcomCheckoutScreen> = computed(
			() => store.getters[Getters.ECOM__GET_CURRENT_CHECKOUT_SCREEN]
		);
		const merchantInfo: ComputedRef<MerchantInfoCache> = computed(() => store.getters[Getters.ECOM__GET_MERCHANT_INFO]);
		const checkoutData: ComputedRef<EcomCheckoutData> = computed(() => store.getters[Getters.ECOM__GET_CHECKOUT_DATA]);

		return {
			currentScreen,
			merchantInfo,
			checkoutData,
			screens: EcomCheckoutScreen,
		};
	},
});
