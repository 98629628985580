import BasePaymentButtonConfiguration from "./BasePaymentButtonConfiguration";

export default class BvrPayConfiguration extends BasePaymentButtonConfiguration {
	public ecomSdkUrl?: string;

	constructor(instance?: BvrPayConfiguration) {
		super();
		Object.assign(this, instance);
	}
}
