import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_dialog = _resolveComponent("checkout-dialog")!
  const _component_container = _resolveComponent("container")!
  const _component_base_checkout = _resolveComponent("base-checkout")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "checkout-page",
    id: "checkout-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_checkout, null, {
        content: _withCtx(() => [
          _createVNode(_component_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_checkout_dialog, { "is-loading": _ctx.isLoading }, null, 8, ["is-loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}