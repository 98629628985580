
import { defineComponent, ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import * as Actions from "@/store/constants/Actions";
import * as Getters from "@/store/constants/Getters";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";

export default defineComponent({
	name: "EcomReceiptSuccess",
	setup() {
		const store = useStore();

		const handleBackToStoreButtonClick = () => {
			store.dispatch(Actions.ECOM__REDIRECT_TO_STORE);
		};

		const isSuccessRedirectUrlExists: ComputedRef<boolean> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const merchantInfo: MerchantInfoCache = store.getters[Getters.ECOM__GET_MERCHANT_INFO];
			
			return !!checkoutData.successRedirectUrl || !!merchantInfo.defaultSuccessRedirectUrl;
		});

		return {
			handleBackToStoreButtonClick,
			isSuccessRedirectUrlExists
		};
	},
});
