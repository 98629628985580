export default class MerchantInfoCache {
	public partnerId?: Number;
	public partnerName?: string;
	public partnerStatusId?: Number;
	public merchantName?: string;
	public merchantStatusId?: Number;
	public merchantLogoUrl?: string;
	public defaultSuccessRedirectUrl?: string;
	public defaultFailureRedirectUrl?: String;
	public defaultCallBackUrl?: string;
	public defaultDeepLinkUrl?: string;
	public canChangePhoneNumber?: Boolean;
	public maxApproveTimeSeconds?: number;
	public maxApproveAttempts?: number;
	public successAutoRedirectTimeSeconds?: number;
	public isHiddenPartnerOrderId?: boolean;
	public isHiddenDescription?: boolean;

	constructor(data?: MerchantInfoCache) {
		Object.assign(this, data);
	}
}
