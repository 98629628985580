
import BaseCheckout from "@/common-components/BaseCheckout.vue";
import CheckoutDialog from "@/components/CheckoutDialog/CheckoutDialog.vue";
import Container from "@/components/Container/Container.vue";
import router from "@/router";
import * as Actions from "@/store/constants//Actions";
import * as Getters from "@/store/constants//Getters";
import * as CommonActions from "@/store/constants/Common/Actions";
import { computed, ComputedRef, defineComponent, onBeforeMount } from "vue";
import { useStore } from "vuex";

export default defineComponent({
	name: "EcomCheckout",
	components: {
		BaseCheckout,
		Container,
		CheckoutDialog,
	},
	setup() {
		const store = useStore();
		const path = router.currentRoute.value.params.id;

		const isLoading: ComputedRef<boolean> = computed(() => {
			return (
				store.getters[Getters.ECOM__GET_IS_APP_WAITING] || store.getters[Getters.ECOM__GET_IS_CONFIRMATION_WAITING]
			);
		});

		onBeforeMount(() => {
			store.dispatch(Actions.ECOM__LOAD_CHECKOUT_DATA, path).then(() => {
				store.dispatch(CommonActions.COMMON__INIT_WEB_SOCKET, path);
			});
		});

		return {
			isLoading,
		};
	},
});
