import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/ecom-error-500.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container = _resolveComponent("container")!
  const _component_base_checkout = _resolveComponent("base-checkout")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "checkout-error-page",
    id: "checkout-error-page"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_checkout, null, {
        content: _withCtx(() => [
          _createVNode(_component_container, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("main", { class: "q-py-xl" }, [
                _createElementVNode("div", { class: "q-mb-xl q-px-md" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    height: "160",
                    title: "Внутрішня помилка",
                    alt: "Помилка 500 - внутрішня помилка",
                    loading: "lazy",
                    class: "page-image q-mx-auto"
                  })
                ]),
                _createElementVNode("div", { class: "page-body" }, [
                  _createElementVNode("h1", {
                    class: "page-title q-mt-none text-center",
                    id: "page-title"
                  }, "Внутрішня помилка"),
                  _createElementVNode("p", {
                    class: "page-text no-margin text-center",
                    id: "page-text"
                  }, [
                    _createTextVNode(" Сервер не зміг виконати запит через непередбачувану помилку."),
                    _createElementVNode("br"),
                    _createTextVNode("Спробуйте ще раз або виберіть інший спосіб оплати. ")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}