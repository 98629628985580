
import { defineComponent } from "vue";
import { useStore } from "vuex";
import * as Actions from "@/store/constants//Actions";
import BaseCheckout from "@/common-components/BaseCheckout.vue";
import Container from "@/components/Container/Container.vue";

export default defineComponent({
	name: "EcomCheckoutErrorPage",
	components: {
		BaseCheckout,
		Container,
	},
	setup() {
		const store = useStore();

		const handleBackToStoreButtonClick = () => {
			store.dispatch(Actions.ECOM__REDIRECT_TO_STORE, true);
		};

		return {
			handleBackToStoreButtonClick,
		};
	},
});
