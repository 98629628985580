import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_test_card_info_content = _resolveComponent("test-card-info-content")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_q_btn, {
      id: "test-card-popup-button",
      icon: "info_outlined",
      label: "Картки для тестування",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTestCardPopup())),
      "no-caps": "",
      flat: ""
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isPopupVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isPopupVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_test_card_info_content)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}