import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-row q-mb-sm" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "description-row" }

import { ref, computed, type Ref, type ComputedRef } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsWindow',
  props: {
    title: {},
    text: {},
    rowLength: {}
  },
  setup(__props: any) {

const props = __props;

const expanded: Ref<boolean> = ref(false);

const staticBox: ComputedRef<boolean> = computed(() => props.text.length < props.rowLength);

const toggleDetailsWindow = (): void => {
	expanded.value = !expanded.value;
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["details-window full-width cursor-pointer q-pa-md", { expanded: expanded.value, static: staticBox.value }]),
    onClick: toggleDetailsWindow
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_q_icon, {
        class: "expand-icon",
        name: "chevron_left"
      })
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ], 2))
}
}

})