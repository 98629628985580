import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = {
  key: 0,
  class: "absolute-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 8 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_payment_confirmation = _resolveComponent("payment-confirmation")!
  const _component_payment_success = _resolveComponent("payment-success")!
  const _component_payment_timeout = _resolveComponent("payment-timeout")!
  const _component_payment_attempts_limit = _resolveComponent("payment-attempts-limit")!
  const _component_receipt_success = _resolveComponent("receipt-success")!
  const _component_receipt_error = _resolveComponent("receipt-error")!
  const _component_payment_cancel = _resolveComponent("payment-cancel")!
  const _component_no_payment_methods_available = _resolveComponent("no-payment-methods-available")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_q_card, {
      class: _normalizeClass(["card q-mx-auto", { transparent: _ctx.isLoading }]),
      id: "checkout-card",
      flat: _ctx.isLoading
    }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_q_spinner_gears, {
                color: "grey-4",
                size: "8em"
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.currentScreen === _ctx.screens.PaymentConfirmation)
                ? (_openBlock(), _createBlock(_component_payment_confirmation, {
                    key: 0,
                    id: "payment-confirmation-panel",
                    ecomMerchantInfo: _ctx.merchantInfo,
                    ecomCheckoutData: _ctx.checkoutData
                  }, null, 8, ["ecomMerchantInfo", "ecomCheckoutData"]))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.PaymentSuccess)
                ? (_openBlock(), _createBlock(_component_payment_success, { key: 1 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.PaymentTimeout)
                ? (_openBlock(), _createBlock(_component_payment_timeout, { key: 2 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.PaymentAttemptsLimitRiched)
                ? (_openBlock(), _createBlock(_component_payment_attempts_limit, { key: 3 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.ReceiptSendSuccess)
                ? (_openBlock(), _createBlock(_component_receipt_success, { key: 4 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.ReceiptSendError)
                ? (_openBlock(), _createBlock(_component_receipt_error, { key: 5 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.PaymentCancel)
                ? (_openBlock(), _createBlock(_component_payment_cancel, { key: 6 }))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.NoPaymentMethodAvailable)
                ? (_openBlock(), _createBlock(_component_no_payment_methods_available, {
                    key: 7,
                    ecomMerchantInfo: _ctx.merchantInfo
                  }, null, 8, ["ecomMerchantInfo"]))
                : _createCommentVNode("", true),
              (_ctx.currentScreen === _ctx.screens.UserCards)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_4, "UserCards"))
                : _createCommentVNode("", true)
            ]))
      ]),
      _: 1
    }, 8, ["flat", "class"])
  ]))
}