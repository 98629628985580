
import { computed, ComputedRef, defineComponent } from "vue";

export default defineComponent({
	name: "PaymentAttemptsCounter",
	props: {
		attemptsAvailable: {
			type: Number,
			isRequired: true,
		},
		attemptsLimit: {
			type: Number,
			isRequired: true,
		},
	},
	setup(props: any) {
		const isCounterVisible = computed(() => props.attemptsAvailable < props.attemptsLimit);

		const attemptsWordVariant: ComputedRef<String> = computed(() => {
			let variant = "спроб";

			const lastDigit: number = parseInt((props.attemptsAvailable || 0).toString().slice(-1));

			if (props.attemptsAvailable >= 5 && props.attemptsAvailable <= 20) {
				return "спроб";
			}
			if (lastDigit == 1) {
				return "спроба";
			}
			if (lastDigit > 1 && lastDigit < 5) {
				return "спроби";
			}

			return variant;
		});

		return {
			attemptsWordVariant,
			isCounterVisible,
		};
	},
});
