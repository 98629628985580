
import { EcomCheckoutScreen } from "@/enums/EcomCheckoutScreen";
import { ErrorCode } from "@/enums/ErrorCode";
import * as Actions from "@/store/constants/Actions";
import * as Getters from "@/store/constants/Getters";
import * as Mutations from "@/store/constants/Mutations";
import { computed, ComputedRef, defineComponent } from "vue";
import { useStore } from "vuex";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";

export default defineComponent({
	name: "EcomPaymentCancel",
	setup() {
		const store = useStore();

		const handleBackToStoreButtonClick = () => {
			if (hasAvailablePaymentAttempts() && !isApproveTimeLimitReached() && !isOrderIsAlreadyPaid()) {
				store.commit(Mutations.ECOM__SET_CURRENT_CHECKOUT_SCREEN, EcomCheckoutScreen.PaymentConfirmation);
			} else {
				store.dispatch(Actions.ECOM__REDIRECT_TO_STORE);
			}
		};

		const isFailRedirectUrlExists: ComputedRef<boolean> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			return !!checkoutData.failureRedirectUrl;
		});

		const getButtonCaption = () => {
			const hasAvailableAttempts = hasAvailablePaymentAttempts();
			const approveTimeLimitReached = isApproveTimeLimitReached();
			const orderIsAlreadyPaid = isOrderIsAlreadyPaid();
			return hasAvailableAttempts && !approveTimeLimitReached && !orderIsAlreadyPaid
				? "Оплатити іншим способом"
				: "До магазину";
		};

		const hasAvailablePaymentAttempts = () => {
			const availablePaymentAttempts = store.getters[Getters.ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS];
			return availablePaymentAttempts > 0;
		};

		const isOrderIsAlreadyPaid = () => {
			return store.getters[Getters.ECOM__GET_PAYMENT_ERROR] === ErrorCode.IsPayedOrder;
		};

		const isApproveTimeLimitReached = () => {
			return store.getters[Getters.ECOM__GET_PAYMENT_ERROR] === ErrorCode.MaxApproveTimeSecondsExceeded;
		};

		const errorCode: ComputedRef<number> = computed(() => store.getters[Getters.ECOM__GET_PAYMENT_ERROR]);

		return {
			errorCode,
			getButtonCaption,
			handleBackToStoreButtonClick,
			isFailRedirectUrlExists
		};
	},
});
