export enum ActionCode {
	None = 0,
	OrderRegistered,
	UserRedirectedToCheckout,
	RedirectToCheckoutError,
	BVRPayPaymentMethodSelected,
	GPayPaymentMethodSelected,
	APayPaymentMethodSelected,
	CardPaymentMethodSelected,
	UserRedirectedToCheckoutFrom3DS,
	ApproveRequest,
	BVRPayPushSent,
	BVRPayPushApproved,
	BVRPayPushCanceled,
	ApproveSuccessful,
	ApproveFailed,
	ThreeDSRedirect,
	ThreeDSPassed,
	ThreeDSError,
	ConfirmRequest,
	ConfirmSuccessful,
	ConfirmError,
	PayInfoSent,
	ReceiptSent,
	ReceiptError,
	UserRedirectedToStore,
	PayRequest,
	PaySuccessful,
	PayError,
	CancelRequest,
	CancelSuccessful,
	CancelError,
	PhoneChanged,
	OTPSent,
	OTPSentError,
	OTPConfirmed,
	OTPCheckError,
	StorefrontApproveRequest,
	StorefrontApproveSuccess,
	StorefrontApproveFailed,
	StorefrontConfirmSent,
	StorefrontConfirmSuccess,
	StorefrontConfirmFailed,
}
