import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-mt-md" }
const _hoisted_2 = { class: "q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_test_card_info_item = _resolveComponent("test-card-info-item")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "content" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "row items-center q-pb-none" }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("strong", { id: "dialog-header" }, "Картки для тестування", -1)),
          _createVNode(_component_q_space),
          _withDirectives(_createVNode(_component_q_btn, {
            icon: "close",
            flat: "",
            round: "",
            dense: ""
          }, null, 512), [
            [_directive_close_popup]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("strong", null, "3DSecure 2.0", -1)),
          _createElementVNode("section", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("span", null, [
              _createElementVNode("b", null, "Challange flow"),
              _createElementVNode("br"),
              _createTextVNode("З переходом на сторінку підтвердження банка-емітента")
            ], -1)),
            _createVNode(_component_test_card_info_item, {
              "card-number": "4149517330877470",
              "otp-code": "123456",
              class: "card-info-item q-mt-md"
            }),
            _createVNode(_component_test_card_info_item, {
              "card-number": "5120707304221165",
              "otp-code": "21815743",
              class: "card-info-item"
            })
          ]),
          _createElementVNode("section", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("span", null, [
              _createElementVNode("b", null, "Frictionless flow"),
              _createElementVNode("br"),
              _createTextVNode("Без переходу на сторінку підтвердження банка-емітента")
            ], -1)),
            _createVNode(_component_test_card_info_item, {
              "card-number": "5120707300000035",
              class: "card-info-item q-mt-md"
            }),
            _createVNode(_component_test_card_info_item, {
              "card-number": "4149517300000020",
              class: "card-info-item"
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Без 3DSecure", -1)),
          _createVNode(_component_test_card_info_item, {
            "card-number": "4999999999990011",
            class: "card-info-item q-mt-md q-mb-md"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}