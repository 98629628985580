
import { Ref, defineComponent, ref, computed, ComputedRef, nextTick } from "vue";
import { useStore } from "vuex";
import * as Mutations from "@/store/constants/Mutations";
import * as Getters from "@/store/constants/Getters";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";

export default defineComponent({
	name: "SumAmountInput",
	props: {
		sumAmount: {
			type: String,
			require: true,
		},
	},
	setup(props, { emit }) {
		const store = useStore();

		const sumAmountInputValue: Ref<string> = ref(props.sumAmount || "0");
		const amountValidationError: Ref<boolean> = ref(false);

		const checkoutData: ComputedRef<EcomCheckoutData> = computed(() => store.getters[Getters.ECOM__GET_CHECKOUT_DATA]);
		const isAmountIncorrect: ComputedRef<boolean> = computed(
			() => sumAmountInputValue.value.startsWith(".") || sumAmountInputValue.value.endsWith(".")
		);

		// Method for validation an amount value
		const validateAmountValue = () => {
			const amountValueNumber = sumAmountInputValue.value.length ? Number(sumAmountInputValue.value) : -1;

			amountValidationError.value = amountValueNumber <= 0 || isAmountIncorrect.value;

			return amountValidationError.value;
		};

		// Method for handling a keypress event for amount input
		const handleKeyPressEvent = (e: KeyboardEvent) => {
			const commaSymbol = ",";
			const dotSymbol = ".";
			const keyIsNaN = isNaN(Number(e.key));
			const isValidSymbol = !keyIsNaN || e.key === dotSymbol || e.key === commaSymbol;

			// Check if the symbol is a number / comma / dot or space
			if (!isValidSymbol || e.code === "Space") {
				e.preventDefault();
				return;
			}

			// Prevent event if the dot a first symbol in the input
			if ((e.key === dotSymbol || e.key === commaSymbol) && !sumAmountInputValue.value.length) {
				e.preventDefault();
				return;
			}

			// Prevent event if the dot symbol already in input
			if ((e.key === dotSymbol || e.key === commaSymbol) && sumAmountInputValue.value.includes(dotSymbol)) {
				e.preventDefault();
				return;
			}
		};

		const handleSumAmountInput = () => {
			nextTick(() => {
				replaceCommaToDot();
				removeAllDotsInStringExceptFirst();
				cutAmountValue();
				saveSumAmountToStore();
				validateAmountValue();
			});
		};

		// Method for saving sum amount to store
		const saveSumAmountToStore = () => {
			const newCheckoutData = Object.assign({}, checkoutData.value, { amount: Number(sumAmountInputValue.value) });
			store.commit(Mutations.ECOM__SET_CHECKOUT_DATA, newCheckoutData);
			emit("changeSumAmount", sumAmountInputValue.value);
		};

		// Method for filtering a paste data
		const handleSumAmountPasteEvent = (e: ClipboardEvent) => {
			const pasteData = e.clipboardData!.getData("text");

			let regexp = /^\d+([.,]\d+)?$/;

			// Prevent if the first simbol is dot
			if (!sumAmountInputValue.value && pasteData.startsWith(".")) {
				e.preventDefault();
			}

			// Condition for checking numbers and dots in value
			if (!pasteData.match(regexp)) {
				e.preventDefault();
			}
		};

		// Method for cutting an input value if the numbers after the dot more than two
		const cutAmountValue = () => {
			const dotIndex = sumAmountInputValue.value.indexOf(".");

			if (dotIndex > 0) {
				const slicedAmount = sumAmountInputValue.value.slice(0, dotIndex + 3);
				sumAmountInputValue.value = slicedAmount;
			}
		};

		// Method for replacing comma to dot
		const replaceCommaToDot = () => {
			sumAmountInputValue.value = sumAmountInputValue.value.replaceAll(",", ".");
		};

		// Method for remove all dots except first
		const removeAllDotsInStringExceptFirst = () => {
			const ammountNumberArr: string | string[] = sumAmountInputValue.value.split(".");
			sumAmountInputValue.value =
				ammountNumberArr.shift() + (ammountNumberArr.length ? "." + ammountNumberArr.join("") : "");
		};

		return {
			sumAmountInputValue,
			amountValidationError,
			handleSumAmountInput,
			handleKeyPressEvent,
			handleSumAmountPasteEvent,
			checkoutData,
		};
	},
});
