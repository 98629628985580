
import { computed, ComputedRef, defineComponent } from "vue";
import LogoPanel from "@/common-components/LogoPanel.vue";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import { useStore } from "vuex";
import * as Actions from "@/store/constants//Actions";
import * as Getters from "@/store/constants/Getters";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";

export default defineComponent({
	name: "NoPaymentMethodsAvailable",
	components: {
		LogoPanel,
	},
	props: {
		ecomMerchantInfo: MerchantInfoCache,
	},
	setup(props: any) {
		const store = useStore();

		const handleBackToStoreButtonClick = () => {
			store.dispatch(Actions.ECOM__REDIRECT_TO_STORE);
		};

		const isFailRedirectUrlExists: ComputedRef<boolean> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			return !!checkoutData.failureRedirectUrl;
		});

		const logoUrl: ComputedRef<string> = computed(() => {
			return props.ecomMerchantInfo ? props.ecomMerchantInfo!.merchantLogoUrl! : "";
		});

		return {
			handleBackToStoreButtonClick,
			logoUrl,
			isFailRedirectUrlExists
		};
	},
});
