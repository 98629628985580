
import { defineComponent } from "vue";

export default defineComponent({
	name: "FieldPopup",
	props: {
		icon: String,
		errorMessage: String,
	},
});
