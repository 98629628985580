
import { defineComponent } from "vue";

export default defineComponent({
	emits: ["click"],
	setup(props, { emit }) {
		const handleCardPayButtonClick = () => emit("click");

		return {
			handleCardPayButtonClick,
		};
	},
});
