
import { AuthType } from "@/enums/AuthType";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import CardPaySendReceiptPayload from "@/models/requests/CardPaySendReceiptPayload";
import router from "@/router";
import * as Actions from "@/store/constants/Actions";
import * as Getters from "@/store/constants/Getters";
import Timer from "@/utilities/Timer";
import { ComputedRef, Ref, computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";

export default defineComponent({
	name: "EcomPaymentSuccess",
	setup() {
		const store = useStore();
		const email: Ref<string> = ref("");
		const emailValidationError: Ref<boolean> = ref(false);
		const errorMessage: Ref<string> = ref("");
		const timer = new Timer();

		onMounted(() => {
			const merchantInfo: MerchantInfoCache = { ...store.getters[Getters.ECOM__GET_MERCHANT_INFO] };
			const successRedirectUrl = { ...store.getters[Getters.ECOM__GET_CHECKOUT_DATA] }.successRedirectUrl;
			const linkToRedirect = successRedirectUrl ? successRedirectUrl : merchantInfo.defaultSuccessRedirectUrl;
			const autoRedirectDelaySeconds: number | undefined | null = merchantInfo.successAutoRedirectTimeSeconds;

			if (!linkToRedirect) {
				return;
			}

			if (autoRedirectDelaySeconds && autoRedirectDelaySeconds > 0) {
				timer.startTimer(autoRedirectDelaySeconds * 1000, () =>
					store.dispatch(Actions.ECOM_GO_TO_LINK, linkToRedirect)
				);
			}
		});

		const handleEmailInputFocus = () => {
			if (isPossibleToSendReceipt.value) {
				timer.cancelTimer();
			}
		};

		const isSuccessRedirectUrlExists: ComputedRef<boolean> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const merchantInfo: MerchantInfoCache = store.getters[Getters.ECOM__GET_MERCHANT_INFO];
			
			return !!checkoutData.successRedirectUrl || !!merchantInfo.defaultSuccessRedirectUrl;
		});

		const isPossibleToSendReceipt: ComputedRef<boolean> = computed(() => {
			const authType: ComputedRef<number | undefined> = computed(
				() => store.getters[Getters.ECOM__GET_CHECKOUT_DATA]?.authType
			);
			return authType.value ? authType.value !== AuthType.preAuth && authType.value !== AuthType.verify : false;
		});

		const handleEmailInput = (value: any): void => {
			errorMessage.value = "";
			emailValidationError.value = false;
			email.value = value;
		};

		const handleBackToStoreButtonClick = () => store.dispatch(Actions.ECOM__REDIRECT_TO_STORE);

		const validateEmail = (email: any): boolean => {
			var regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$/;
			return regExp.test(email);
		};

		const onSubmit = (): void => {
			if (!email.value || email.value.length === 0) {
				errorMessage.value = "Вкажіть Ваш e-mail";
				emailValidationError.value = true;
				return;
			}

			const isValidEmail = validateEmail(email.value);

			if (!isValidEmail) {
				errorMessage.value = "Некоректний формат електронної адреси";
				emailValidationError.value = true;
				return;
			}

			const id = router.currentRoute.value.params.id as string;

			const sendReceiptPayload: CardPaySendReceiptPayload = {
				email: email.value,
				checkoutId: id,
			};

			store.dispatch(Actions.SEND_RECEIPT_TO_EMAIL, sendReceiptPayload);
		};

		return {
			AuthType,
			isPossibleToSendReceipt,
			handleEmailInputFocus,
			email,
			emailValidationError,
			errorMessage,
			handleEmailInput,
			validateEmail,
			handleBackToStoreButtonClick,
			onSubmit,
			isSuccessRedirectUrlExists,
		};
	},
});
