
import { defineComponent } from "vue";

export default defineComponent({
	name: "LogoPanel",
	props: {
		logoUrl: {
			type: String,
			required: false,
		},
	},
});
