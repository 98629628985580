import { ActionCode } from "@/enums/ActionCode";

export default class ActionLogRequest {
	public checkoutId?: string;
	public actionCode?: ActionCode;

	constructor(instance?: ActionLogRequest) {
		Object.assign(this, instance);
	}
}
