import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/ecom-payment-cancel.svg'


const _hoisted_1 = {
  class: "card",
  id: "ecom-payment-cancel"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "card-text no-margin text-center",
  id: "card-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "q-mb-lg" }, [
      _createElementVNode("img", {
        src: _imports_0,
        height: "128",
        title: "Платіж не пройшов",
        alt: "Помилка платежу",
        loading: "lazy",
        class: "card-image q-mx-auto",
        id: "card-image"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", {
        class: "card-title q-mt-none text-center",
        id: "card-title"
      }, "Платіж не пройшов", -1)),
      _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
        [_directive_t, { path: `error.${_ctx.errorCode}` }]
      ])
    ]),
    _withDirectives(_createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      unelevated: "",
      "no-caps": "",
      padding: "md",
      label: _ctx.getButtonCaption(),
      class: "card-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBackToStoreButtonClick()))
    }, null, 8, ["label"]), [
      [_vShow, _ctx.isFailRedirectUrlExists]
    ])
  ]))
}