import BasePaymentButtonConfiguration from "./BasePaymentButtonConfiguration";

export default class GooglePayConfiguration extends BasePaymentButtonConfiguration {
	public mode?: string;

	constructor(instance?: GooglePayConfiguration) {
		super();
		Object.assign(this, instance);
	}
}
