import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/mastercard-logo.svg'
import _imports_1 from '@/assets/images/visa-logo.svg'
import _imports_2 from '@/assets/images/prostir-logo.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  width: "40"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  width: "40"
}
const _hoisted_3 = {
  key: 2,
  src: _imports_2,
  width: "70"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_popup = _resolveComponent("field-popup")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_payment_attempts_counter = _resolveComponent("payment-attempts-counter")!
  const _component_test_card_info_panel = _resolveComponent("test-card-info-panel")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[8] || (_cache[8] = event => _ctx.handleFormSubmit(event))
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_input, {
        name: "cardnumber",
        autocomplete: "cc-number",
        ref: "cardNumberInput",
        id: "card-number-input",
        class: "card-input",
        modelValue: _ctx.cardNumber,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cardNumber) = $event)),
          _cache[2] || (_cache[2] = value => _ctx.handleCardNumberInput(value))
        ],
        label: "Номер картки",
        "text-color": "grey-2",
        mask: "#### #### #### ####",
        color: "grey-8",
        borderless: "",
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleCardNumberFieldFocus())),
        "stack-label": ""
      }, {
        append: _withCtx(() => [
          (_ctx.isMasterCardPaymentSystem)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.isVisaPaymentSystem)
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.isProstirPaymentSystem)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.bankLogoUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 3,
                class: "q-ml-sm",
                src: _ctx.bankLogoUrl,
                alt: _ctx.bankName,
                width: "26"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_field_popup, {
            style: {"width":"240px"},
            icon: "error",
            errorMessage: "Неправильний номер картки"
          }, null, 512), [
            [_vShow, _ctx.cardNumberValidationError]
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives((_openBlock(), _createBlock(_component_q_input, {
        name: "exp-date",
        autocomplete: "cc-exp",
        ref: "expireDateInput",
        id: "expire-date",
        label: "Термін дії",
        "text-color": "grey-2",
        modelValue: _ctx.expirationDate,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expirationDate) = $event)),
          _cache[5] || (_cache[5] = value => _ctx.handleExpireDateInput(value))
        ],
        maxlength: 5,
        mask: "##/##",
        "stack-label": "",
        color: "grey-8",
        borderless: "",
        onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleExpireDateFieldFocus())),
        class: "card-input q-mr-xs q-mt-xs"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_field_popup, {
            style: {"width":"210px"},
            icon: "error",
            errorMessage: "Неправильний термін дії"
          }, null, 512), [
            [_vShow, _ctx.cardExpirationDateValidationError]
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])), [
        [_directive_focus, _ctx.isExpireDateFieldFocused]
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_q_input, {
        ref: "cvvCodeInput",
        name: "cvc",
        autocomplete: "cc-csc",
        id: "cvv-code",
        modelValue: _ctx.cvv,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cvv) = $event)),
          _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleCvvCodeInput()))
        ],
        label: "CVV/CVC",
        "text-color": "grey-2",
        mask: "###",
        type: "password",
        "stack-label": "",
        color: "grey-8",
        borderless: "",
        class: "card-input q-mt-xs"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_field_popup, {
            style: {"width":"180px"},
            icon: "error",
            errorMessage: "Неправильний CVV"
          }, null, 512), [
            [_vShow, _ctx.cardCvvValidationError]
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])), [
        [_directive_focus, _ctx.isCvvCodeFieldFocused]
      ])
    ]),
    _createVNode(_component_payment_attempts_counter, {
      "attempts-available": _ctx.availablePaymentAttempts,
      "attempts-limit": _ctx.paymentAttemptsLimit
    }, null, 8, ["attempts-available", "attempts-limit"]),
    (_ctx.showTestCardsPanel)
      ? (_openBlock(), _createBlock(_component_test_card_info_panel, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_q_btn, {
      ref: "payButton",
      id: "pay-button",
      "no-caps": "",
      label: _ctx.buttonLabel(),
      "text-color": "white",
      color: "orange-7",
      type: "submit",
      disabled: _ctx.isPayButtonDisabled
    }, null, 8, ["label", "disabled"])
  ], 32))
}