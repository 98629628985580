import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/ecom-payment-limit.svg'


const _hoisted_1 = {
  class: "card",
  id: "ecom-payment-limit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"q-mb-lg\" data-v-03940d61><img src=\"" + _imports_0 + "\" height=\"128\" title=\"Перевищенно спроб\" alt=\"Помилка перевищення спроб\" loading=\"lazy\" class=\"card-image q-mx-auto\" id=\"card-image\" data-v-03940d61></div><div class=\"card-body\" data-v-03940d61><h2 class=\"card-title q-mt-none text-center\" id=\"card-title\" data-v-03940d61>Спроби оплати вичерпано</h2><p class=\"card-text no-margin text-center\" id=\"card-text\" data-v-03940d61> Для поточного замовлення всі спроби оплати вичерпано. Поверніться в магазин і виберіть інший спосіб оплати. </p></div>", 2)),
    _withDirectives(_createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      unelevated: "",
      "no-caps": "",
      padding: "md",
      title: "Повернутись до магазину",
      class: "card-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBackToStoreButtonClick()))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" До магазину ")
      ])),
      _: 1
    }, 512), [
      [_vShow, _ctx.isFailRedirectUrlExists]
    ])
  ]))
}