import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/ecom-error-404.svg'


const _hoisted_1 = { class: "q-py-xl" }
const _hoisted_2 = { class: "page-buttons q-mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_container = _resolveComponent("container")!
  const _component_base_checkout = _resolveComponent("base-checkout")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "checkout-page-not-found",
    id: "checkout-page-not-found"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_checkout, null, {
        content: _withCtx(() => [
          _createVNode(_component_container, null, {
            default: _withCtx(() => [
              _createElementVNode("main", _hoisted_1, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "q-mb-xl q-px-md" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    height: "160",
                    title: "Сторінка не знайдена",
                    alt: "Помилка 404 - сторінка не знайдена",
                    loading: "lazy",
                    class: "page-image q-mx-auto"
                  })
                ], -1)),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "page-body" }, [
                  _createElementVNode("h1", {
                    class: "page-title q-mt-none text-center",
                    id: "page-title"
                  }, "Сторінка не знайдена"),
                  _createElementVNode("p", {
                    class: "page-text no-margin text-center",
                    id: "page-text"
                  }, " Можливо вона застаріла, була видалена або були введені невірні дані в адресному рядку ")
                ], -1)),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_btn, {
                    unelevated: "",
                    "no-caps": "",
                    padding: "md xl",
                    title: "Повернутися на головну",
                    class: "page-button full-width",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBackToStoreButtonClick()))
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("span", { id: "button-label-mobile" }, "На головну", -1),
                      _createElementVNode("span", { id: "button-label-desktop" }, "Повернутися на головну", -1)
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}