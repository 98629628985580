
import { defineComponent } from "vue";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard/copy-to-clipboard.js';;

export default defineComponent({
	name: "TestCardInfoItem",
	props: {
		cardNumber: {
			type: String,
			required: true,
		},
		otpCode: {
			type: String,
			required: false,
		},
	},
	setup() {
		const handleCopy = async (value: string) => {
			await copyToClipboard(value);
		};

		return {
			handleCopy,
		};
	},
});
