
import FieldPopup from "@/common-components/FieldPopup.vue";
import PaymentAttemptsCounter from "@/components/PaymentAttemptsCounter/PaymentAttemptsCounter.vue";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import CardDetails from "@/models/CardDetails";
import * as Actions from "@/store/constants//Actions";
import * as Getters from "@/store/constants/Getters";
import { computed, ComputedRef, defineComponent, Ref, ref } from "vue";
import TestCardInfoPanel from "./TestCardInfoPanel.vue";
import { useStore } from "vuex";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";
import CardInformationRequest from "@/models/requests/CardInformationRequest";
import CardInformationResponse from "@/models/responses/CardInformationResponse";
import { ErrorCode } from "@/enums/ErrorCode";

export default defineComponent({
	name: "CardInput",
	components: {
		FieldPopup,
		TestCardInfoPanel,
		PaymentAttemptsCounter,
	},
	props: {
		amount: {
			type: String,
			require: true,
		},
	},
	setup(props: any) {
		const isVisaPaymentSystem = ref(false);
		const isMasterCardPaymentSystem = ref(false);
		const isProstirPaymentSystem = ref(false);
		const cardNumber = ref();
		const expirationDate = ref();
		const cvv = ref();
		const cardNumberValidationError = ref(false);
		const cardExpirationDateValidationError = ref(false);
		const cardCvvValidationError = ref(false);
		const isExpireDateFieldFocused = ref(false);
		const isCvvCodeFieldFocused = ref(false);
		const bankLogoUrl: Ref<string> = ref("");
		const bankName: Ref<string> = ref("");

		const store = useStore();
		const buttonLabel = () => `Сплатити ${props.amount || "0.00"}`;

		const checkoutData: ComputedRef<EcomCheckoutData> = computed(() => store.getters[Getters.ECOM__GET_CHECKOUT_DATA]);

		const isPayButtonDisabled: ComputedRef<boolean> = computed(
			() => !checkoutData.value.amount || props.amount.startsWith(".") || props.amount.endsWith(".")
		);

		const luhnAlgorithm = (cardNumber: string) => {
			cardNumber = cardNumber.replace(/\D/g, "");

			var check = 0;
			var even = false;

			for (var n = cardNumber.length - 1; n >= 0; n--) {
				var nextDigit = parseInt(cardNumber.charAt(n), 10);

				if (even && (nextDigit *= 2) > 9) {
					nextDigit -= 9;
				}

				check += nextDigit;
				even = !even;
			}

			return check % 10 == 0;
		};

		const showTestCardsPanel: ComputedRef<Boolean> = computed(
			() => store.getters[Getters.ECOM__CAN_SHOW_TEST_CARDS_INFO]
		);

		const handleCardNumberFieldFocus = () => {
			isExpireDateFieldFocused.value = false;
			isCvvCodeFieldFocused.value = false;
		};

		const handleExpireDateFieldFocus = () => {
			isCvvCodeFieldFocused.value = false;
		};

		const handleCardNumberInput = (value: string | number | null) => {
			const cardNumberLength = 16;
			const stringValue = String(value);
			const valueWithoutSpaces = stringValue.replaceAll(" ", "");
			resetCardInformation();
			resetPaymentSystemLogo();
			resetErrors();

			if (valueWithoutSpaces.length > cardNumberLength - 1) {
				if (!isValidCardNumber(valueWithoutSpaces)) {
					cardNumberValidationError.value = true;
					return;
				} else {
					isExpireDateFieldFocused.value = true;
					isVisaPaymentSystem.value = isVisaCardNumber(stringValue);
					isMasterCardPaymentSystem.value = isMasterCardNumber(stringValue);
					isProstirPaymentSystem.value = isProstirCardNumber(stringValue);
					cardNumberValidationError.value = false;

					// Get card information info from the server to show bank logo in input
					getCardInformation(valueWithoutSpaces);
				}
			}
		};

		const getCardInformation = (cardNumber: string) => {
			const request: CardInformationRequest = new CardInformationRequest({
				cardNumber: cardNumber,
				cultureName: "uk-UA",
			});

			store.dispatch(Actions.ECOM__GET_CARD_INFORMATION, request).then((responseData: CardInformationResponse) => {
				if (responseData.errorCode === ErrorCode.Success) {
					bankLogoUrl.value = responseData.logoUrl!;
					bankName.value = responseData.bankName!;
				}
			});
		};

		const isVisaCardNumber = (value: string) => {
			const visaFirstDigit = "4";
			const firstDigit = value.toString().substring(0, 1);
			return firstDigit === visaFirstDigit;
		};

		const isMasterCardNumber = (value: string) => {
			const mastercardFirstDigit = "5";
			const firstDigit = value.toString().substring(0, 1);
			return firstDigit === mastercardFirstDigit;
		};

		const isProstirCardNumber = (value: string) => {
			const prostirFirstDigits = "9804";
			const firstFourDigits = value.toString().substring(0, 4);
			return firstFourDigits === prostirFirstDigits;
		};

		const resetPaymentSystemLogo = () => {
			isVisaPaymentSystem.value = false;
			isMasterCardPaymentSystem.value = false;
			isProstirPaymentSystem.value = false;
		};

		const resetCardInformation = () => {
			bankLogoUrl.value = "";
			bankName.value = "";
		};

		const handleExpireDateInput = (expireDate: string | number | null) => {
			resetErrors();
			const value = expireDate!.toString().replaceAll(" ", "");

			if (value.length >= "xx/xx".length) {
				if (isValidCardExpirationDate(value)) {
					isCvvCodeFieldFocused.value = true;
					isExpireDateFieldFocused.value = false;
				} else {
					cardExpirationDateValidationError.value = true;
				}
			}
		};

		const handleCvvCodeInput = () => {
			resetErrors();
		};

		const isValidCardExpirationDate = (expirationDate: string) => {
			const minimumValidYear = 20;
			const date = expirationDate.split("/");
			const month = parseInt(date[0]);
			const year = parseInt(date[1]);

			return month > 0 && month <= 12 && year >= minimumValidYear;
		};

		const resetErrors = () => {
			cardNumberValidationError.value = false;
			cardExpirationDateValidationError.value = false;
			cardCvvValidationError.value = false;
		};

		const isValidCardCvvCode = (value: string) => {
			return value && value.length === 3;
		};

		const isValidCardNumber = (cardNumber: string) => {
			return cardNumber && cardNumber.charAt(0) !== "0" && luhnAlgorithm(cardNumber);
		};

		const handleFormSubmit = (event: any) => {
			event.preventDefault();

			if (!isValidCardNumber(cardNumber.value)) {
				cardNumberValidationError.value = true;
				return;
			}
			if (!isValidCardExpirationDate(expirationDate.value)) {
				cardExpirationDateValidationError.value = true;
				return;
			}
			if (!isValidCardCvvCode(cvv.value)) {
				cardCvvValidationError.value = true;
				return;
			}
			resetErrors();

			const date = expirationDate.value.split("/");
			const month = date[0];
			const year = date[1];
			const cardDetails = new CardDetails({
				cardNumber: cardNumber.value.replace(/\D/g, ""),
				expireDate: new Date(2000 + parseInt(year), month),
				cvv: cvv.value,
			});

			store.dispatch(Actions.ECOM__SEND_CARD_PAY_APPROVE_REQUEST, cardDetails);
		};

		const availablePaymentAttempts: ComputedRef<number> = computed(
			() => store.getters[Getters.ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS]
		);

		const paymentAttemptsLimit: ComputedRef<number> = computed(() => {
			const merchantInfo: MerchantInfoCache = store.getters[Getters.ECOM__GET_MERCHANT_INFO];
			return merchantInfo ? merchantInfo.maxApproveAttempts! : 0;
		});

		return {
			cardNumber,
			cardCvvValidationError,
			cardNumberValidationError,
			cardExpirationDateValidationError,
			availablePaymentAttempts,
			expirationDate,
			cvv,
			resetErrors,
			buttonLabel,
			handleFormSubmit,
			handleCardNumberInput,
			handleExpireDateInput,
			handleCardNumberFieldFocus,
			handleExpireDateFieldFocus,
			showTestCardsPanel,
			isExpireDateFieldFocused,
			isCvvCodeFieldFocused,
			handleCvvCodeInput,
			isVisaPaymentSystem,
			isMasterCardPaymentSystem,
			isProstirPaymentSystem,
			paymentAttemptsLimit,
			checkoutData,
			isPayButtonDisabled,
			bankLogoUrl,
			bankName,
		};
	},
});
