import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_pay_button = _resolveComponent("card-pay-button")!
  const _component_payment_attempts_counter = _resolveComponent("payment-attempts-counter")!
  const _component_card_input = _resolveComponent("card-input")!
  const _component_q_slide_transition = _resolveComponent("q-slide-transition")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card_pay_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePanelStateChange())),
      ref: "card-pay-button"
    }, null, 512),
    _withDirectives(_createVNode(_component_payment_attempts_counter, {
      "attempts-available": _ctx.availablePaymentAttempts,
      "attempts-limit": _ctx.paymentAttemptsLimit
    }, null, 8, ["attempts-available", "attempts-limit"]), [
      [_vShow, !_ctx.isExpand]
    ]),
    _createVNode(_component_q_slide_transition, {
      ref: "card-input-panel",
      duration: 500,
      appear: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("div", {
            class: "q-pa-md",
            id: "card-payment-header"
          }, "Оплата карткою", -1)),
          _createVNode(_component_card_input, { amount: _ctx.amount }, null, 8, ["amount"])
        ], 512), [
          [_vShow, _ctx.isExpand]
        ])
      ]),
      _: 1
    }, 512)
  ]))
}