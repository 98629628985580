
import { defineComponent } from "vue";
import Container from "@/components/Container/Container.vue";
import CheckoutHeader from "@/components/Header/Header.vue";
import CheckoutFooter from "@/components/Footer/Footer.vue";

export default defineComponent({
	name: "BaseCheckout",
	components: {
		Container,
		CheckoutHeader,
		CheckoutFooter,
	},
});
