import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "payment-attempts-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isCounterVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createTextVNode(" Залишилось ")),
        _createElementVNode("span", null, _toDisplayString(_ctx.attemptsAvailable), 1),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("span", null, _toDisplayString(_ctx.attemptsWordVariant), 1)
      ]))
    : _createCommentVNode("", true)
}