import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/checkout-telegram.svg'
import _imports_1 from '@/assets/images/checkout-viber.svg'


const _hoisted_1 = {
  class: "footer row justify-center",
  id: "checkout-footer"
}
const _hoisted_2 = { class: "contacts row wrap justify-between" }
const _hoisted_3 = {
  class: "contact contacts-partners row wrap",
  id: "contacts-partners"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"contact contacts-social-icons row no-wrap\" data-v-4ee4ef8c><a href=\"https://t.me/bankvostokhelpbot\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"q-mr-md\" id=\"footer-telegram-link\" data-v-4ee4ef8c><img src=\"" + _imports_0 + "\" width=\"42\" height=\"42\" title=\"Відкрити бот Telegram\" alt=\"Telegram bot\" loading=\"lazy\" data-v-4ee4ef8c></a><a href=\"https://bankvostok.com.ua/_help_viber\" target=\"_blank\" rel=\"noopener noreferrer\" id=\"footer-viber-link\" data-v-4ee4ef8c><img src=\"" + _imports_1 + "\" width=\"42\" height=\"42\" title=\"Відкрити бот Viber\" alt=\"Viber bot\" loading=\"lazy\" data-v-4ee4ef8c></a></div><div class=\"contact contacts-general-phone\" data-v-4ee4ef8c><a href=\"tel:0800307010\" class=\"contacts-link text-no-wrap\" title=\"Зателефонувати\" id=\"footer-general-phone\" data-v-4ee4ef8c>0 800 30 70 10</a><p class=\"contacts-subtitle no-margin\" data-v-4ee4ef8c>Безкоштовно по Україні</p></div><div class=\"contact contacts-intenational-phone\" data-v-4ee4ef8c><a href=\"tel:0443937010\" class=\"contacts-link text-no-wrap\" title=\"Зателефонувати\" id=\"footer-int-phone\" data-v-4ee4ef8c>044 393 70 10</a><p class=\"contacts-subtitle no-margin\" data-v-4ee4ef8c>Для міжнародних дзвінків</p></div><div class=\"contact contacts-email\" data-v-4ee4ef8c><a href=\"mailto:bank@bankvostok.com.ua\" class=\"contacts-link\" title=\"Надіслати листа\" id=\"footer-email-link\" data-v-4ee4ef8c>bank@bankvostok.com.ua</a><p class=\"contacts-subtitle no-margin\" data-v-4ee4ef8c>Пиши нам на пошту</p></div>", 4)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/images/mastercard_logo.svg'),
          width: "39",
          height: "24",
          loading: "lazy",
          alt: "Логотип Mastercard",
          class: "contacts-partners-logo contacts-partners-mastercard-logo",
          id: "mastercard-logo"
        }, null, 8, _hoisted_4),
        _createElementVNode("img", {
          src: require('@/assets/images/single_visa_logo.svg'),
          width: "60",
          height: "20",
          loading: "lazy",
          alt: "Логотип Visa",
          class: "contacts-partners-logo contacts-partners-visa-logo",
          id: "visa-logo"
        }, null, 8, _hoisted_5),
        _createElementVNode("img", {
          src: require('@/assets/images/prostir_logo.svg'),
          width: "102",
          height: "20",
          loading: "lazy",
          alt: "Логотип Простір",
          class: "contacts-partners-logo contacts-partners-prostir-logo",
          id: "prostir-logo"
        }, null, 8, _hoisted_6),
        _createElementVNode("img", {
          src: require('@/assets/images/pci-dss-compliant-logo.svg'),
          width: "65",
          height: "24",
          loading: "lazy",
          alt: "Логотип PCI DSS Сompliant",
          class: "contacts-partners-logo contacts-partners-pci-logo",
          id: "pci-logo"
        }, null, 8, _hoisted_7),
        _createElementVNode("img", {
          src: require('@/assets/images/ecom-apple-pay.svg'),
          width: "44",
          height: "28",
          loading: "lazy",
          alt: "Логотип Apple Pay",
          class: "contacts-partners-logo contacts-partners-apple-pay-logo",
          id: "apple-pay-logo"
        }, null, 8, _hoisted_8),
        _createElementVNode("img", {
          src: require('@/assets/images/ecom-google-pay.svg'),
          width: "54",
          height: "28",
          loading: "lazy",
          alt: "Логотип Google Pay",
          class: "contacts-partners-logo contacts-partners-google-pay-logo",
          id: "google-pay-logo"
        }, null, 8, _hoisted_9),
        _createElementVNode("img", {
          src: require('@/assets/images/ecom-bvr-pay.svg'),
          width: "57",
          height: "28",
          loading: "lazy",
          alt: "Логотип Bvr Pay",
          class: "contacts-partners-logo contacts-partners-bvr-pay-logo",
          id: "bvr-pay-logo"
        }, null, 8, _hoisted_10)
      ])
    ])
  ]))
}