import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_panel = _resolveComponent("logo-panel")!
  const _component_payment_info_panel = _resolveComponent("payment-info-panel")!
  const _component_card_pay_panel = _resolveComponent("card-pay-panel")!
  const _component_payment_methods_skeleton = _resolveComponent("payment-methods-skeleton")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_logo_panel, {
      ref: "logo-panel",
      logoUrl: _ctx.logoUrl
    }, null, 8, ["logoUrl"]),
    _createVNode(_component_payment_info_panel, {
      ref: "info-panel",
      id: "info-panel",
      class: "q-mb-md",
      sellerName: _ctx.sellerName,
      partnerOrderId: _ctx.partnerOrderId,
      sumAmount: _ctx.sumAmountRef,
      isAmountEditable: _ctx.isAmountEditable,
      description: _ctx.description,
      isHiddenDescription: _ctx.isHiddenDescription,
      onChangeSumAmount: _ctx.handleSumAmountInput
    }, null, 8, ["sellerName", "partnerOrderId", "sumAmount", "isAmountEditable", "description", "isHiddenDescription", "onChangeSumAmount"]),
    _withDirectives(_createElementVNode("article", null, [
      _createElementVNode("div", {
        id: "apple-pay",
        class: "apple-pay-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleApplePayButtonClick())),
        style: {"margin-bottom":"-5px"}
      }),
      _createElementVNode("div", {
        id: "google-pay",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUserAction(_ctx.actionCode.GPayPaymentMethodSelected)))
      }),
      (_ctx.isPhoneNumberProvided)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: "bvr-pay",
            mode: "pay",
            fill: "true",
            style: {"margin-bottom":"-5px"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleUserAction(_ctx.actionCode.BVRPayPaymentMethodSelected)))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_card_pay_panel, {
        ref: "card-pay",
        id: "card-pay",
        amount: _ctx.sumAmountRef,
        onExpand: _cache[3] || (_cache[3] = isExpand => _ctx.handleCardInputExpand(isExpand))
      }, null, 8, ["amount"])
    ], 512), [
      [_vShow, _ctx.isPaymentMethodsReady]
    ]),
    _withDirectives(_createElementVNode("article", null, [
      _createVNode(_component_payment_methods_skeleton, { segmentsQuantity: _ctx.availablePaymentMethodsQuantity }, null, 8, ["segmentsQuantity"])
    ], 512), [
      [_vShow, !_ctx.isPaymentMethodsReady]
    ])
  ]))
}