import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/ecom-success-tick.svg'


const _hoisted_1 = {
  class: "card",
  id: "ecom-payment-success"
}
const _hoisted_2 = { class: "q-mb-lg" }
const _hoisted_3 = {
  key: 0,
  class: "card-text no-margin text-center",
  id: "card-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "q-mb-lg" }, [
      _createElementVNode("img", {
        src: _imports_0,
        height: "124",
        title: "Оплата пройшла!",
        alt: "Успішна оплата",
        loading: "lazy",
        class: "card-image q-mx-auto",
        id: "card-image"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("h2", {
        class: "card-title q-mt-none text-center",
        id: "card-title"
      }, "Оплата пройшла!", -1)),
      (_ctx.isPossibleToSendReceipt)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Надішліть квитанцію про оплату "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isPossibleToSendReceipt)
      ? (_openBlock(), _createBlock(_component_q_form, {
          key: 0,
          onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSubmit())),
          class: "form column q-mb-md",
          ref: "emailForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              outlined: "",
              "hide-bottom-space": "",
              clearable: "",
              "clear-icon": "close",
              placeholder: "Електронна пошта",
              class: "form-input q-mb-md",
              ref: "emailInput",
              modelValue: _ctx.email,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                _cache[1] || (_cache[1] = value => _ctx.handleEmailInput(value))
              ],
              modelModifiers: { trim: true },
              onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleEmailInputFocus())),
              error: _ctx.emailValidationError,
              "error-message": _ctx.errorMessage
            }, null, 8, ["modelValue", "error", "error-message"]),
            _createVNode(_component_q_btn, {
              unelevated: "",
              "no-caps": "",
              type: "submit",
              padding: "md",
              class: "button button-main",
              ref: "submitButton",
              label: "Відправити"
            }, null, 512)
          ]),
          _: 1
        }, 512))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_q_btn, {
      flat: _ctx.isPossibleToSendReceipt,
      unelevated: "",
      "no-caps": "",
      padding: "md",
      class: _normalizeClass(['button', _ctx.isPossibleToSendReceipt ? 'button-secondary' : 'button-main']),
      label: "До магазину",
      ref: "backButton",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleBackToStoreButtonClick()))
    }, null, 8, ["flat", "class"]), [
      [_vShow, _ctx.isSuccessRedirectUrlExists]
    ])
  ]))
}