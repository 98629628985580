import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    class: "sum-amount-input",
    type: "text",
    ref: "sumAmountInput",
    color: "black",
    modelValue: _ctx.sumAmountInputValue,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sumAmountInputValue) = $event)),
      _ctx.handleSumAmountInput
    ],
    "no-error-icon": true,
    error: _ctx.amountValidationError,
    onKeypress: _ctx.handleKeyPressEvent,
    onPaste: _ctx.handleSumAmountPasteEvent
  }, null, 8, ["modelValue", "error", "onUpdate:modelValue", "onKeypress", "onPaste"]))
}