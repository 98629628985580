import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "base-checkout",
  class: "column",
  style: {"min-height":"100vh"}
}
const _hoisted_2 = { class: "flex items-center col" }
const _hoisted_3 = { style: {"border-top":"1px solid #e9e9ea"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_header = _resolveComponent("checkout-header")!
  const _component_container = _resolveComponent("container")!
  const _component_checkout_footer = _resolveComponent("checkout-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_checkout_header)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_container, { id: "footer-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_checkout_footer)
        ]),
        _: 1
      })
    ])
  ]))
}