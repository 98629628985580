
import PaymentAttemptsCounter from "@/components/PaymentAttemptsCounter/PaymentAttemptsCounter.vue";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import * as Getters from "@/store/constants/Getters";
import { computed, ComputedRef, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import CardInput from "./child-components/CardInput.vue";
import CardPayButton from "./child-components/CardPayButton.vue";

export default defineComponent({
	components: {
		CardPayButton,
		PaymentAttemptsCounter,
		CardInput,
	},
	props: {
		amount: String,
	},
	setup(props, { emit }: { emit: any }) {
		const isExpand = ref(false);
		const store = useStore();

		const handlePanelStateChange = () => {
			isExpand.value = !isExpand.value;
			emit("expand", isExpand.value);
		};

		const availablePaymentAttempts: ComputedRef<number> = computed(
			() => store.getters[Getters.ECOM__GET_AVAILABLE_PAYMENT_ATTEMPTS]
		);

		const paymentAttemptsLimit: ComputedRef<number> = computed(() => {
			const merchantInfo: MerchantInfoCache = store.getters[Getters.ECOM__GET_MERCHANT_INFO];
			return merchantInfo ? merchantInfo.maxApproveAttempts! : 0;
		});

		return {
			isExpand,
			handlePanelStateChange,
			availablePaymentAttempts,
			paymentAttemptsLimit,
		};
	},
});
