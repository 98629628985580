import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "info-field" }
const _hoisted_2 = {
  class: "value",
  id: "seller-name-value"
}
const _hoisted_3 = {
  key: 0,
  class: "info-field"
}
const _hoisted_4 = {
  class: "value",
  id: "partner-order-value"
}
const _hoisted_5 = {
  key: 1,
  class: "info-field"
}
const _hoisted_6 = {
  class: "info-field",
  id: "sum-amount-field"
}
const _hoisted_7 = {
  key: 0,
  class: "value",
  id: "sum-amount-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_window = _resolveComponent("details-window")!
  const _component_sum_amount_input = _resolveComponent("sum-amount-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("span", {
        class: "label",
        id: "seller-name-label"
      }, "Торговець:", -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.sellerName || "-"), 1)
    ]),
    (!_ctx.isOrderNumberHidden)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("span", {
            class: "label",
            id: "partner-order-label"
          }, "Замовлення:", -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.partnerOrderId || "-"), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.description && !_ctx.isHiddenDescription)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_details_window, {
            title: "Призначення платежу",
            text: _ctx.description,
            rowLength: 40
          }, null, 8, ["text"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "label",
        id: "sum-amount-label"
      }, "До сплати, UAH:", -1)),
      (!_ctx.isAmountEditable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.sumAmount || "-"), 1))
        : _createCommentVNode("", true),
      (_ctx.isAmountEditable)
        ? (_openBlock(), _createBlock(_component_sum_amount_input, {
            key: 1,
            sumAmount: _ctx.sumAmount,
            onChangeSumAmount: _ctx.handleSumAmountInput
          }, null, 8, ["sumAmount", "onChangeSumAmount"]))
        : _createCommentVNode("", true)
    ])
  ]))
}