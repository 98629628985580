import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "no-payments-available-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_panel = _resolveComponent("logo-panel")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_logo_panel, {
      "logo-url": _ctx.logoUrl,
      class: "q-mb-xl",
      ref: "logo-panel"
    }, null, 8, ["logo-url"]),
    _cache[1] || (_cache[1] = _createElementVNode("span", { id: "no-payments-available-message" }, "Немає доступних способів оплати", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { id: "no-payments-available-description" }, "Поверністься до магазину і виберіть інший варіант оплати", -1)),
    _withDirectives(_createVNode(_component_q_btn, {
      id: "back-to-store-button",
      "text-color": "white",
      color: "orange-7",
      "no-caps": "",
      onClick: _ctx.handleBackToStoreButtonClick
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { id: "button-label-mobile" }, "На головну", -1),
        _createElementVNode("span", { id: "button-label-desktop" }, "Повернутися на головну", -1)
      ])),
      _: 1
    }, 8, ["onClick"]), [
      [_vShow, _ctx.isFailRedirectUrlExists]
    ])
  ]))
}