import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/ecom-payment-cancel.svg'


const _hoisted_1 = {
  class: "card",
  id: "ecom-receipt-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "q-mb-lg" }, [
      _createElementVNode("img", {
        src: _imports_0,
        height: "124",
        title: "Квитанцію не відправлено!",
        alt: "Помилка відправки квитанції",
        loading: "lazy",
        class: "card-image q-mx-auto",
        id: "card-image"
      })
    ], -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-body" }, [
      _createElementVNode("h2", {
        class: "card-title q-mt-none text-center",
        id: "card-title"
      }, "Квитанцію не відправлено!")
    ], -1)),
    _withDirectives(_createVNode(_component_q_btn, {
      ref: "backToStoreButton",
      unelevated: "",
      "no-caps": "",
      padding: "md",
      title: "Повернутись до магазину",
      class: "card-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBackToStoreButtonClick()))
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" До магазину ")
      ])),
      _: 1
    }, 512), [
      [_vShow, _ctx.isFailRedirectUrlExists]
    ])
  ]))
}