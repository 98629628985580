
import { defineComponent, ComputedRef, computed } from "vue";
import { useStore } from "vuex";
import * as Actions from "@/store/constants/Actions";
import * as Getters from "@/store/constants/Getters";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";

export default defineComponent({
	name: "EcomPaymentTimeout",
	setup() {
		const store = useStore();

		const handleBackToStoreButtonClick = () => {
			store.dispatch(Actions.ECOM__REDIRECT_TO_STORE);
		};

		const isFailRedirectUrlExists: ComputedRef<boolean> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			return !!checkoutData.failureRedirectUrl;
		});

		return {
			handleBackToStoreButtonClick,
			isFailRedirectUrlExists
		};
	},
});
