
import LogoPanel from "@/common-components/LogoPanel.vue";
import CardPayPanel from "@/components/CardPayPanel/CardPayPanel.vue";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import BvrPayConfiguration from "@/models/configurations/BvrPayConfiguration";
import GooglePayConfiguration from "@/models/configurations/GooglePayConfiguration";
import { EcomCheckoutData } from "@/models/EcomCheckoutData";
import HtmlRootElementsCollection from "@/models/HtmlRootElementsCollection";
import CheckoutConfiguration from "@/models/responses/CheckoutConfiguration";
import * as Actions from "@/store/constants/Actions";
import * as Getters from "@/store/constants/Getters";
import { computed, ComputedRef, defineComponent, onMounted, PropType, ref } from "vue";
import { useStore } from "vuex";
import PaymentMethodsSkeleton from "../PaymentMethodsSkeleton/PaymentMethodsSkeleton.vue";
import PaymentInfoPanel from "./child-components/PaymentInfoPanel.vue";
import * as Mutations from "@/store/constants/Mutations";
import { ActionCode } from "@/enums/ActionCode";
import router from "@/router";
import ActionLogRequest from "@/models/requests/ActionLogRequest";

export default defineComponent({
	name: "PaymentConfirmation",
	components: {
		LogoPanel,
		PaymentInfoPanel,
		CardPayPanel,
		PaymentMethodsSkeleton,
	},
	props: {
		ecomCheckoutData: {
			type: Object as PropType<EcomCheckoutData>,
			isRequired: false,
		},
		ecomMerchantInfo: {
			type: Object as PropType<MerchantInfoCache>,
			isRequired: false,
		},
	},
	setup(props: any, { emit }: { emit: any }) {
		const store = useStore();
		const checkoutId = router.currentRoute.value.params.id as string;

		const handleCardInputExpand = (isExpand: boolean) => {
			handleUserAction(ActionCode.CardPaymentMethodSelected);
			emit("expand", isExpand);
		};

		const availablePaymentMethodsQuantity: ComputedRef<number> = computed(() => {
			const checkoutData: EcomCheckoutData = store.getters[Getters.ECOM__GET_CHECKOUT_DATA];
			const defaultPaymentMethodsQuantity = 3;

			if (checkoutData && !checkoutData.phoneNumber) {
				return defaultPaymentMethodsQuantity - 1;
			}

			return defaultPaymentMethodsQuantity;
		});

		onMounted(() => {
			store.dispatch(Actions.ECOM__LOAD_CHECKOUT_CONFIGURATION).then((response: any) => {
				if (!response || !response.data) {
					console.error("Unable to load checkout configuration");
					return;
				}
				const checkoutConfig: CheckoutConfiguration = response.data;
				store.commit(Mutations.ECOM__SET_CHECKOUT_CONFIGURATION, checkoutConfig);

				// BVR Pay setup
				const bvrPayRootElements = new HtmlRootElementsCollection({
					scriptElementId: "bvrpay",
					buttonElementId: "bvr-pay",
				});
				const bvrConfig = new BvrPayConfiguration({
					rootElements: bvrPayRootElements,
					ecomSdkUrl: checkoutConfig.ecomSdkUrl,
				});
				store.dispatch(Actions.ECOM__ADD_BVR_PAY, bvrConfig);

				// Google Pay setup
				const googlePayRootElements = new HtmlRootElementsCollection({
					scriptElementId: "gpay",
					buttonElementId: "google-pay",
				});
				const googlePayConfiguration = new GooglePayConfiguration({
					rootElements: googlePayRootElements,
					mode: checkoutConfig.googlePayMode,
				});
				store.dispatch(Actions.ECOM__ADD_GOOGLE_PAY, googlePayConfiguration);

				// Apple Pay setup
				store.dispatch(
					Actions.ECOM__ADD_APPLE_PAY,
					new HtmlRootElementsCollection({ scriptElementId: "apay", buttonElementId: "apple-pay" })
				);
			});
		});

		const handleUserAction = (actionCode: ActionCode) => {
			const request: ActionLogRequest = new ActionLogRequest({ checkoutId: checkoutId, actionCode: actionCode });
			store.dispatch(Actions.ECOM__SEND_USER_ACTION_CODE, request);
		};

		const handleApplePayButtonClick = () => {
			handleUserAction(ActionCode.APayPaymentMethodSelected);
			store.dispatch(Actions.ECOM__PERFORM_APPLE_PAY_PAYMENT);
		};

		const isPhoneNumberProvided: ComputedRef<boolean> = computed(() => {
			if (!props.ecomCheckoutData) {
				return false;
			}
			return !!props.ecomCheckoutData.phoneNumber;
		});

		const isPaymentMethodsReady: ComputedRef<boolean> = computed(() => {
			return store.getters[Getters.ECOM__GET_IS_ALL_PAYMENT_METHODS_READY_TO_USE];
		});

		const logoUrl: ComputedRef<string> = computed(() => {
			return props.ecomMerchantInfo ? props.ecomMerchantInfo!.merchantLogoUrl! : "";
		});

		const sellerName: ComputedRef<string> = computed(() => {
			return props.ecomMerchantInfo ? props.ecomMerchantInfo.merchantName! : "-";
		});

		const partnerOrderId: ComputedRef<string> = computed(() => {
			return props.ecomCheckoutData ? props.ecomCheckoutData.partnerOrderId! : "-";
		});

		const sumAmount: ComputedRef<string> = computed(() => {
			return props.ecomCheckoutData ? props.ecomCheckoutData.amount!.toFixed(2) : "-";
		});

		const isAmountEditable: ComputedRef<boolean> = computed(() => {
			return props.ecomMerchantInfo ? props.ecomMerchantInfo.canChangeAmount : false;
		});

		const description: ComputedRef<string> = computed(() => {
			return props.ecomCheckoutData.description;
		});

		const isHiddenDescription: ComputedRef<boolean> = computed(() => {
			return props.ecomMerchantInfo.isHiddenDescription;
		});

		const sumAmountRef = ref<string>(sumAmount.value);

		const handleSumAmountInput = (amount: string) => {
			sumAmountRef.value = amount;
		};

		return {
			logoUrl,
			sellerName,
			partnerOrderId,
			handleCardInputExpand,
			handleApplePayButtonClick,
			isPhoneNumberProvided,
			isPaymentMethodsReady,
			availablePaymentMethodsQuantity,
			sumAmountRef,
			isAmountEditable,
			handleSumAmountInput,
			handleUserAction,
			description,
			isHiddenDescription,
			actionCode: ActionCode,
		};
	},
});
