
import { defineComponent } from "vue";
import TestCardInfoItem from "./TestCardInfoItem.vue";

export default defineComponent({
	name: "TestCardInfoContent",
	components: {
		TestCardInfoItem,
	},
});
