import script from "./DetailsWindow.vue?vue&type=script&lang=ts&setup=true"
export * from "./DetailsWindow.vue?vue&type=script&lang=ts&setup=true"

import "./DetailsWindow.vue?vue&type=style&index=0&id=7df966e0&lang=scss"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
